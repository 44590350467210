<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="12"
      md="11"
    >
      <v-alert
        v-if="configuracao.score"
        type="error"
        dense
      >
        Produto exclusivo do Clube Styllus
      </v-alert>
      <v-btn
        v-if="this.$route.fullPath.includes('configuracao')"
        small
        color="primary"
        @click="$router.go(-1)"
      >
        Voltar
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="11"
    >
      <v-card class="configs">
        <h4>Configuração do produto</h4>
        <v-text-field
          v-model="configuracao.referencia"
          label="Referência"
          name="referencia"
          type="text"
        />
        <v-text-field
          v-model="configuracao.idExterno"
          label="Códido Produto (integração)"
          name="IdExterno"
          type="number"
        />
        <v-text-field
          v-model="configuracao.peso"
          label="Peso"
          name="peso"
          type="text"
        />
        <v-text-field
          v-model="configuracao.estoque"
          label="Estoque"
          name="estoque"
          type="text"
        />
        <v-select
          v-model="configuracao.tipoDeProdutoId"
          label="Tipo"
          :items="tipos"
          item-text="nome"
          item-value="id"
        />
        <v-autocomplete
          v-model="configuracao.corId"
          label="Cor"
          :items="cores"
          item-text="nome"
          item-value="corId"
        />
        <v-autocomplete
          v-model="configuracao.tamanhoId"
          label="Tamanho"
          :items="tamanhos"
          item-text="nome"
          item-value="tamanhoId"
        />
        <v-img
          v-if="configuracao.idExterno"
          id="imgprincipal"
          name="imgprincipal"
          :src="configuracao.urlImagem"
          width="250"
        />
        <v-file-input
          v-model="foto"
          accept="image/*"
          chips
          label="Imagem"
        />
      </v-card>
      <v-card class="configs">
        <h4>Precificação</h4>
        <p class="m-0">
          Preço base do produto
        </p>
        <v-text-field
          v-model="configuracao.valor"
          label="Preço base"
          name="valor"
          type="text"
        />
        <p class="m-0">
          Preço promocional do produto (Indica uma promoção no portal - "Dê:
          R$ 19,90 | Por: R$ 11,90")
        </p>
        <v-text-field
          v-model="configuracao.valorPromocional"
          label="Preço promocional"
          name="valorPromocional"
          type="text"
        />
        <p class="m-0">
          Preços exclusivos para o clube Styllus:
        </p>
        <v-text-field
          v-for="(price, i) in configuracao.levelPrices"
          :key="i"
          v-model="price.valor"
          :label="price.level.name"
          type="number"
        />
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="11"
    >
      <v-btn
        color="secondary"
        class="btn-add"
        block
        @click="save"
      >
        {{ id ? 'atualizar' : 'salvar' || this.$router.params.id ? 'atualizar' : salvar }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ConfiguracaoProdutoForm',
    props: {
      id: {
        type: Number,
        default: () => null,
      },
    },
    data: () => ({
      foto: null,
      cores: [],
      tamanhos: [],
      configuracao: {
        levels: [],
      },
      nameRules: [(v) => !!v || 'Este campo é obrigatório'],
      tipos: [
        { nome: 'Fidelidade (diamantes)', id: 1 },
        { nome: 'Encomenda', id: 2 },
      ],
    }),
    mounted () {
      this.$http.get('/produto/cor').then((resp) => {
        this.cores = resp.data.rows
      })
      this.$http.get('/produto/tamanho').then((resp) => {
        this.tamanhos = resp.data.rows
      })
      if (this.id || this.$route.params.id) {
        this.load()
      } else {
        this.$http.get('/gamefication/level').then((resp) => {
          this.configuracao.levelPrices = resp.data.rows
        })
      }
    },
    methods: {
      async load () {
        let id = null
        if (this.id !== null) { id = this.id } else if (this.$route.params.id !== null) { id = this.$route.params.id }
        const resp = await this.$http.get(
          `/produto/configuracao/${id}`,
        )
        this.configuracao = resp.data
      },
      save () {
        const missingClubPrice = this.configuracao.levelPrices.filter(
          (x) => !x.valor || x.valor <= 0,
        )
        if (this.configuracao.score && missingClubPrice.length > 0) {
          missingClubPrice.forEach((element) => {
            this.$toast.error('Falta o valor para o nível ' + element.level.name)
          })
          return
        }
        this.configuracao.peso = parseFloat(this.configuracao.peso)
        this.configuracao.valor = parseFloat(this.configuracao.valor)
        this.configuracao.idExterno = parseInt(this.configuracao.idExterno)
        if (this.configuracao.valorPromocional) {
          this.configuracao.valorPromocional = parseFloat(
            this.configuracao.valorPromocional,
          )
        }
        if (this.id || this.$route.params.id) {
          this.put()
        } else {
          this.configuracao.produtoId = this.$route.params.produtoid
          this.post()
        }
      },
      put () {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        const formData = new FormData()
        this.configuracao.produtoConfiguracaoId = this.configuracao.id
        if (this.foto) {
          formData.append('foto', this.foto)
        }
        formData.append('produto', JSON.stringify(this.configuracao))
        this.$http
          .put('/produto/configuracao', formData, config)
          .then((resp) => {
            this.$toast.success('Operação realizada com sucesso')
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      post () {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        const formData = new FormData()
        this.configuracao.produtoConfiguracaoId = this.configuracao.id
        if (this.foto) {
          formData.append('foto', this.foto)
        }
        formData.append('produto', JSON.stringify(this.configuracao))
        this.$http
          .post('/produto/configuracao', formData, config)
          .then((resp) => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.go(-1)
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.configs {
  padding: 16px;
  margin-top: 16px;
}
.v-alert--dense {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
